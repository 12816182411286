import React from "react";
import { Link } from "react-router-dom";
function Complaint() {  
    return (
        <>
          <section id="home" style={{ padding: "0 !important" }}>
    <div style={{ marginTop: "8.7%" }} />
          <main>
          <section className="static-sections static-header container border-bottom text-center section-head">
          <h1>Investor Complaint Status
</h1>
        </section>
            <div className="service-block__head" style={{ paddingBottom: 100 }}>
              <div className="container">
                <div className="service-block__text">
                  <div className="textwidget">
                    <h4 style={{ color: "#31708f" }}>Data for June'2024</h4>
                    <div style={{ overflowX: "auto" }}>
                      <table width="100%">
                        <thead>
                          <tr>
                            <th>
                              <strong>Sr. No.</strong>
                            </th>
                            <th>
                              <strong>Received from</strong>
                            </th>
                            <th>
                              <strong>Carried forward from previous month</strong>
                            </th>
                            <th>
                              <strong>Received during the month</strong>
                            </th>
                            <th>
                              <strong>Total Pending</strong>
                            </th>
                            <th>
                              <strong>Resolved*</strong>
                            </th>
                            <th colSpan={2}>
                              <strong>Pending at the end of the month**</strong>
                            </th>
                            <th>
                              <strong>Average Resolution time^ (in days)</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>
                              <strong>Pending for less than 3 months</strong>
                            </td>
                            <td>
                              <strong>Pending for more than 3 months</strong>
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              <strong>Directly from Investors</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              <strong>SEBI (SCORES)</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              <strong>Stock Exchanges</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>
                              <strong>Other Sources (if any)</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <strong>Grand Total</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>
                      &nbsp;
                      <br />
                      &nbsp;
                    </p>
                    <p>
                      *Should include complaints of previous months resolved in the
                      current month, if any.
                    </p>
                    <p>
                      **Should include total complaints pending as on the last day of
                      the month, if any.
                    </p>
                    <p>
                      ^Average resolution time is the sum total of time taken to
                      resolve each complaint in the current month divided by total
                      number of complaints resolved in the current month.
                    </p>
                    <p>
                      &nbsp;
                      <br />
                      &nbsp;
                    </p>
                    <h4 style={{ color: "#31708f" }}>
                      Trend of monthly disposal of complaints
                      <br />
                    </h4>
                    <div style={{ overflowX: "auto" }}>
                      <table width="100%">
                        <thead>
                          <tr>
                            <th>
                              <strong>Sr. No.</strong>
                            </th>
                            <th>
                              <strong>Month</strong>
                            </th>
                            <th>
                              <strong>Carried forward from previous month</strong>
                            </th>
                            <th>
                              <strong>Received</strong>
                            </th>
                            <th>
                              <strong>Resolved*</strong>
                            </th>
                            <th>
                              <strong>Pending**</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <strong>April 2024</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              <strong>May 2024</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              <strong>June 2024</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>
                              <strong>July 2024</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>
                              <strong>August 2024</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>
                              <strong>September 2024</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>
                              <strong>October 2024</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>
                              <strong>November 2024</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>
                              <strong>December 2024</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>
                              <strong>January 2025</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>
                              <strong>Ferbruary 2025</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>
                              <strong>March 2025</strong>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <strong>Grand Total</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>
                      &nbsp;
                      <br />
                      &nbsp;
                    </p>
                    <h4 style={{ color: "#31708f" }}>
                      Trend of annual disposal of complaints
                    </h4>
                    <div style={{ overflowX: "auto" }}>
                      <table width="100%">
                        <thead>
                          <tr>
                            <th>
                              <strong>Sr. No.</strong>
                            </th>
                            <th>
                              <strong>Year</strong>
                            </th>
                            <th>
                              <strong>Carried forward from previous year</strong>
                            </th>
                            <th>
                              <strong>Received during the year</strong>
                            </th>
                            <th>
                              <strong>Resolved during the year</strong>
                            </th>
                            <th>
                              <strong>Pending at the end of the year</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <strong>2023-24</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              <strong>2024-25</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <strong>Grand Total</strong>
                            </td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </section>
      </>
      
    );
    }

export default Complaint;
